import { Image, ImageProps } from 'expo-image';
import { StyleSheet } from 'react-native';

import ShimmerLoader from './ShimmerLoader';

const blurhash = 'LLAKNU_JNaR+~2${IrR-MLH]IDVu';
const placeholder = { blurhash };

type Size = 'small' | 'medium' | 'large' | 'flow_medium' | 'extraLarge';

type Props = {
	isLoading?: boolean;
	avatarUrl?: ImageProps['source'];
	size: Size;
	noShimmer?: boolean;
};

const sizeMapping: Record<Props['size'], number> = {
	small: 24,
	medium: 30,
	flow_medium: 40,
	large: 74,
	extraLarge: 110,
};

const AccountAvatar = ({ isLoading, avatarUrl, size, noShimmer }: Props) => {
	const sizeStyle = styles[size];

	return (
		<ShimmerLoader
			stopAutoRun={!isLoading || noShimmer}
			visible={!!avatarUrl}
			style={sizeStyle}
		>
			<Image
				source={avatarUrl}
				style={sizeStyle}
				contentFit="cover"
				placeholder={placeholder}
				accessibilityLabel="avatar"
				transition={!noShimmer ? 500 : undefined}
				cachePolicy="disk"
			/>
		</ShimmerLoader>
	);
};

const AVATAR_BORDER_RADIUS = 999;

const avatarStyles = Object.keys(sizeMapping).reduce(
	(obj, size) => ({
		...obj,
		[size]: {
			height: sizeMapping[size as Size],
			width: sizeMapping[size as Size],
			borderRadius: AVATAR_BORDER_RADIUS,
		},
	}),
	{} as Record<Size, { height: number; width: number; borderRadius: number }>,
);

const styles = StyleSheet.create({
	...avatarStyles,
});

export default AccountAvatar;
