import MenuDotsIcon from '@wearemojo/icons/Bold/EssentionalUI/MenuDotsIcon';
import ArrowLeftIcon from '@wearemojo/icons/Outline/Arrows/AltArrowLeftIcon';
import ArrowRightIcon from '@wearemojo/icons/Outline/Arrows/AltArrowRightIcon';
import CloseIcon from '@wearemojo/icons/Outline/EssentionalUI/CloseIcon';
import HelpUncontainedIcon from '@wearemojo/icons/Outline/EssentionalUI/HelpUncontainedIcon';
import TuningIcon from '@wearemojo/icons/Outline/SettingsFineTuning/TuningIcon';
import { BrandColor } from '@wearemojo/ui-constants';
import { ComponentType } from 'react';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import PressableOpacity from './PressableOpacity';
import { MojoIcon } from './utils/types';

type Props = {
	onPress?: () => void;
	type: ContainedActionType;
	position?: 'start' | 'center' | 'end';
};

const ContainedAction = ({ type, onPress, position = 'start' }: Props) => {
	const {
		styles,
		theme: { iconSize },
	} = useStyles(stylesheet, { position });
	const Icon = ContainedActionIcon[type];
	return (
		<PressableOpacity
			onPress={onPress}
			style={styles.root}
			testID={`${type}-action`}
		>
			<Icon color={BrandColor.white} size={iconSize.sm} />
		</PressableOpacity>
	);
};

const BORDER_RADIUS = 16;
const SIZE = 32;

const stylesheet = createStyleSheet(({ colors }) => ({
	root: {
		height: SIZE,
		width: SIZE,
		borderRadius: BORDER_RADIUS,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: `${colors.content_neutral600}99`,
		alignSelf: 'flex-start',

		variants: {
			position: {
				start: {
					alignSelf: 'flex-start',
				},
				center: {
					alignSelf: 'center',
				},
				end: {
					alignSelf: 'flex-end',
				},
			},
		},
	},
}));

type ContainedActionType =
	| 'back'
	| 'close'
	| 'forward'
	| 'help'
	| 'more'
	| 'settings';

const ContainedActionIcon: Record<
	ContainedActionType,
	ComponentType<MojoIcon>
> = {
	back: ArrowLeftIcon,
	close: CloseIcon,
	forward: ArrowRightIcon,
	help: HelpUncontainedIcon,
	more: MenuDotsIcon,
	settings: TuningIcon,
};

export default ContainedAction;
